import { addClass, removeClass, preventEvent, callEndpoint, getQuery, isIos, isInStandaloneMode } from 'client/browser'

import { fork } from 'shared/task'

const create = container => {
    const getCodeForm = container.querySelector('.js-get-code-form')
    const signInForm = container.querySelector('.js-sign-in-form')

    const emailField = container.querySelector('.js-email')
    const emailError = container.querySelector('.js-email-error')
    const codeField = container.querySelector('.js-code')
    const codeError = container.querySelector('.js-code-error')
    const signInButton = container.querySelector('.js-sign-in')
    const getCodeButton = container.querySelector('.js-get-code')
    const haveCodeButton = container.querySelector('.js-have-code')
    const needCodeButton = container.querySelector('.js-need-code')

    const installMessagePopup = document.querySelector('.js-install-message-popup')
    const installMessagePopupCloseButton = document.querySelector('.js-install-message-close')

    if (isIos() === true && isInStandaloneMode() === false) {
        setTimeout(() => {
            addClass('install-message__popup--visible', installMessagePopup)
        }, 1000)
    }

    const closePopup = e => {
        preventEvent(e)
        removeClass('install-message__popup--visible', installMessagePopup)
    }

    const doGetCode = e => {
        preventEvent(e)

        removeClass('signin-view__error--visible', emailError)

        const email = emailField.value.toLowerCase()

        if (email === '') {
            addClass('signin-view__error--visible', emailError)
        } else {
            removeClass('signin-view__form--visible', getCodeForm)
            addClass('signin-view__form--visible', signInForm)

            const options = getQuery(window.location.search)
            var culture = 'en'

            if (options && options.culture) {
                culture = options.culture
            }

            fork(
                err => console.log(err),
                status => {
                    if (status === 200) {
                        showSignIn()
                    } else {
                        console.log('failed token')
                    }
                },
                callEndpoint('/signin/token', 'POST', {
                    email,
                    culture
                })
            )
        }
    }

    const doSignIn = e => {
        preventEvent(e)

        removeClass('signin-view__error--visible', codeError)

        const code = codeField.value.toLowerCase()

        if (code === '') {
            addClass('signin-view__error--visible', codeError)
        } else {
            fork(
                err => console.log(err),
                status => {
                    if (status === 200) {
                        window.location = '/'
                    } else {
                        console.log('failed sign in')
                    }
                },
                callEndpoint('/signin/access', 'POST', {
                    code
                })
            )
        }
    }

    const showSignIn = () => {
        removeClass('signin-view__form--visible', getCodeForm)
        addClass('signin-view__form--visible', signInForm)
    }

    const showGetCode = () => {
        addClass('signin-view__form--visible', getCodeForm)
        removeClass('signin-view__form--visible', signInForm)
    }

    const switchToSignIn = e => {
        preventEvent(e)
        showSignIn()
    }

    const switchToGetCode = e => {
        preventEvent(e)
        showGetCode()
    }

    const addEvents = () => {
        getCodeButton.addEventListener('click', doGetCode)
        signInButton.addEventListener('click', doSignIn)
        haveCodeButton.addEventListener('click', switchToSignIn)
        needCodeButton.addEventListener('click', switchToGetCode)
        installMessagePopupCloseButton.addEventListener('click', closePopup)
    }

    const removeEvents = () => {
        signInButton.removeEventListener('click', doSignIn)
        getCodeButton.removeEventListener('click', doGetCode)
        haveCodeButton.removeEventListener('click', switchToSignIn)
        needCodeButton.removeEventListener('click', switchToGetCode)
        installMessagePopupCloseButton.removeEventListener('click', closePopup)
    }

    const destroy = () => {
        removeEvents()
    }

    addEvents()

    return {
        destroy
    }
}

create(document)
