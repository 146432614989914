import {
    Task,
    of
} from 'shared/task'

import {
    forEach,
    curry,
    contains,
    split,
    compose,
    map,
    toPairs,
    join,
    replace,
    reduce,
    isNil,
    isEmpty
} from 'shared/funcy'

const show = (element) => element.style.display = 'block'

const hide = (element) => element.style.display = 'none'

const addClass = curry((classname, element) => element.classList.add(classname))

const removeClass = curry((classname, element) => element.classList.remove(classname))

const hasClass = curry((classname, element) => contains(classname, element.classList))

const toggleClass = (classname, element) => hasClass(classname, element) ? removeClass(classname, element) : addClass(classname, element)

const addClasses = (classlist, element) => {
    element.removeAttribute('class')
    forEach((classname) => addClass(element, classname), classlist)
}

const getText = (element) => element.innerText

const setText = (text, element) => element.innerText = text

const setHtml = (html, element) => element.innerHTML = html

const getValue = (element) => element.value

const setValue = (text, element) => element.value = text

const readData = (name, element) => element.dataset[name]

const writeData = (name, value, element) => element.dataset[name] = value

const createFragment = () => document.createDocumentFragment()

const createElement = (type, classname, inner) => {
    const element = document.createElement(type)

    if (classname !== '') {
        addClass(classname, element)
    }

    if (inner !== '') {
        element.innerHTML = inner
    }

    return element
}

const scrollIntoView = (elem, options) => elem.scrollIntoView(options || {
    behavior: 'smooth',
    block: 'start'
})

const transition = (element) => Task((_, res) => {
    const transitionEnd = () => {
        element.removeEventListener('transitionend', transitionEnd)
        res()
    }

    element.addEventListener('transitionend', transitionEnd)
})

const addTransition = (classname, element) => {
    if (hasClass(classname, element) === false) {
        addClass(classname, element)
        return transition(element)
    } else {
        return of()
    }
}

const removeTransition = (classname, element) => {
    if (hasClass(classname, element) === true) {
        removeClass(classname, element)
        return transition(element)
    } else {
        return of()
    }
}

const linkOverride = (e) => {
    e.preventDefault()
    const newLocation = e.currentTarget.getAttribute('href')

    if (newLocation !== undefined && newLocation.substr(0, 1) !== '#') {
        window.location = newLocation
    }
}
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
}

const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

const overrideMobileLinks = () => {
    if (isInStandaloneMode()) {
        forEach((link) => link.addEventListener('click', linkOverride), document.querySelectorAll('a'))
    }
}

const preventPullToRefresh = () => {

    let lastTouchY
    let maybePreventPullToRefresh = false

    const ptrStart = (e) => {
        if (e.touches.length !== 1) {
            return
        }

        lastTouchY = e.touches[0].pageY
        maybePreventPullToRefresh = (window.pageYOffset === 0)
    }

    const ptrMove = (e) => {
        const touchY = e.touches[0].pageY
        const touchYDelta = touchY - lastTouchY
        lastTouchY = touchY

        if (maybePreventPullToRefresh === true) {
            maybePreventPullToRefresh = false
            if (touchYDelta > 0) {
                e.preventDefault()
            }
        }
    }

    document.addEventListener('touchstart', ptrStart)
    document.addEventListener('touchmove', ptrMove, {
        passive: false
    })
}

const noop = () => {}

const preventEvent = (e) => e.preventDefault()

const preventEventPropagation = (e) => {
    e.stopPropagation()
    e.stopImmediatePropagation()
}

const preventAll = (e) => {
    preventEvent(e)
    preventEventPropagation(e)
}

const hasPassiveEvents = () => {

    let passive = false

    const options = Object.defineProperty({}, 'passive', {
        get() {
            passive = true
        }
    })

    window.addEventListener('passiveTest', noop, options)
    window.removeEventListener('passiveTest', noop, options)
    return passive
}

const loadPartial = (path, method = 'GET', data = {}) => Task((rej, res) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = (e) => res(e.target.response)
    xhr.onerror = () => rej(xhr.status)
    xhr.open(method, path)
    xhr.responseType = 'document'
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(data))
})

const loadJson = (path, method = 'GET', data = {}) => Task((rej, res) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = (e) => res(e.target.response)
    xhr.onerror = () => rej(xhr.status)
    xhr.open(method, path)
    xhr.responseType = 'json'
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(data))
})

const callEndpoint = (path, method = 'GET', data = {}) => Task((_, res) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => res(xhr.status)
    xhr.onerror = () => res(xhr.status)
    xhr.open(method, path)
    xhr.responseType = 'json'
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(data))
})

const saveLocal = curry((key, value) => Task((rej, res) => {
    if (window.localStorage !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(value))
        res('complete')
    } else {
        rej('Local Storage not available')
    }
}))

const loadLocal = (key) => Task((rej, res) => {
    if (window.localStorage !== undefined) {
        res(window.localStorage.getItem(key))
    } else {
        rej('Local Storage not available')
    }
})

const containsQuery = (url) => url.indexOf('?') > -1

const getQuery = (querystring) => {
    if (isNil(querystring) === true || isEmpty(querystring) === true) {
        return undefined
    }

    return compose(reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
    }, {}), map(split('=')), split('&'), replace('?', ''))(querystring)
}

const setQuery = compose(join('&'), map((item) => `${item[0]}=${item[1]}`), toPairs)

export {
    show,
    hide,
    hasClass,
    toggleClass,
    addClass,
    removeClass,
    addClasses,
    readData,
    writeData,
    getText,
    setText,
    setHtml,
    getValue,
    setValue,
    getQuery,
    setQuery,
    containsQuery,
    createFragment,
    createElement,
    scrollIntoView,
    transition,
    addTransition,
    removeTransition,
    overrideMobileLinks,
    preventPullToRefresh,
    hasPassiveEvents,
    preventEvent,
    preventEventPropagation,
    preventAll,
    loadPartial,
    loadJson,
    callEndpoint,
    saveLocal,
    loadLocal,
    isIos,
    isInStandaloneMode
}